<template>
  <div>
    <div class="handleBtnClass">
      <div style="margin-right: 5px; width: 15%">
        <el-select v-model="searchObj.firstSelect" clearable placeholder="标签">
          <el-option v-for="item in firstSelects" :key="item.value" :value="item.value" :label="item.label">
          </el-option>
        </el-select>
      </div>
      <div style="margin-right: 5px; width: 15%">
        <el-select v-model="searchObj.secondSelect" clearable placeholder="类型">
          <el-option v-for="item in secondSelects" :key="item.value" :value="item.value" :label="item.label">
          </el-option>
        </el-select>
      </div>
      <div style="margin-right: 5px">
        <el-input v-model="searchObj.inquireValue" placeholder="请输入商品名称！"></el-input>
      </div>
      <div style="margin-right: 5px">
        <el-button type="success" size="mini" @click="handleInquire">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_inquire"></span>
            <span style="margin-left: 5px">查询</span>
          </div>
        </el-button>
      </div>
      <div style="margin-right: 5px">
        <el-button type="primary" size="mini" @click="handleConfirm">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_add"></span>
            <span style="margin-left: 5px">确认</span>
          </div>
        </el-button>
      </div>
    </div>

    <div class="file_form">
      <el-row>
        <!-- <el-col :span="4">
          <div class="file">
            <div class="file_level1" @click="FileShowClick">
              <img v-if="isFileShow" style="width: 10px; height: 10px" :src="require('@/assets/mianAssets/文件夹减.png')"
                alt="" />
              <img v-if="!isFileShow" style="width: 10px; height: 10px" :src="require('@/assets/mianAssets/文件夹加.png')"
                alt="" />
              <img style="width: 22px; height: 22px; margin-left: 5px" :src="require('@/assets/mianAssets/文件夹.png')"
                alt="" />
              <a href="#">全部文件</a>
            </div>
            <div class="file_level2" v-if="isFileShow">
              <ul>
                <li v-for="item in filStorages" :key="item.id" style="display: flex; align-items: center"
                  @click="fileClick(item.id)">
                  <img style="width: 12px; margin-right: 3px" :src="require('@/assets/mianAssets/空白页.png')" alt="" />
                  <a href="#">
                    <span :class="isLabel == item.id ? 'label' : ''">
                      {{ item.classify_name }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </el-col> -->
        <el-col :span="4">
          <!-- 文件夹处 -->
          <List :classList="filStorages" @allFile="allFile" @labelClick="labelClick" />
        </el-col>
        <el-col :span="20">
          <!-- 表格 -->
          <el-table ref="multipleTable" border :data="tableData" v-loading="isLoading" tooltip-effect="dark"
            style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" align="center"> </el-table-column>
            <el-table-column label="图片">
              <template slot-scope="scope">
                <img :src="`http://txx.com${scope.row.thumbnail}`" alt="" width="100%" />
              </template>
            </el-table-column>
            <el-table-column prop="product_name" label="名称"></el-table-column>
            <el-table-column prop="specifications" sortable label="规格"></el-table-column>
            <el-table-column prop="unit" label="单位"></el-table-column>
            <el-table-column prop="market_price" sortable label="市场价"></el-table-column>
            <el-table-column prop="sales_price" sortable label="销售价"></el-table-column>
          </el-table>
          <div class="tableBottom">
            <span v-if="isResult">显示第&nbsp;{{ (currentPage - 1) * 5 + 1 }}&nbsp;至&nbsp;{{
              (currentPage - 1) * 5 + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tabletotalNum }}&nbsp;项</span>
            <span v-if="!isResult">显示&nbsp;0&nbsp;项结果，共&nbsp;0&nbsp;项</span>
            <div style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
              <el-pagination background layout="prev, pager, next" :total="tabletotalNum" :current-page="currentPage"
                :page-size="5" @current-change="currentChange">
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import List from "@/components/main/list";
export default {
  components: { List },
  data() {
    return {
      cid: 0,
      commercial_id:
        localStorage.getItem("commercial_id") ||
        sessionStorage.getItem("commercial_id"),
      searchObj: {
        firstSelect: "",
        secondSelect: "",
        inquireValue: "",
      },

      firstSelects: [
        {
          "label": "推荐",
          "value": 0
        },
        {
          "label": "新品",
          "value": 1
        },
        {
          "label": "精选",
          "value": 2
        }
      ],
      secondSelects: [
        {
          "label": "普通商品",
          "value": 0
        },
        {
          "label": "同城商品",
          "value": 1
        },
        {
          "label": "虚拟商品",
          "value": 2
        },
        {
          "label": "赠品",
          "value": 3
        }
      ],

      isFileShow: true,
      isLabel: -1,
      filStorages: [],

      isLoading: true,
      tableData: [],
      copyTabelData: [],
      multipleSelection: [],
      isResult: true,
      tabletotalNum: 0,
      currentPage: 1,
      currentLength: 5,
      perPage: 0,
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.inquireList();
    this.filStoragesDatas();
  },
  computed: {},
  methods: {
    allFile() {
      // this.isFileShow = !this.isFileShow;
      if (this.cid == 0) return;
      this.cid = 0;
      this.inquireList();
    },
    labelClick(id) {
      this.cid = id;
      this.inquireList();
    },
    // 多选数据 -- 表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页 -- 当前页改变传值
    currentChange(val) {
      this.currentPage = val;
      this.inquireList();
    },
    // 文件目录 -- 点击全部文件
    FileShowClick() {
      this.isFileShow = !this.isFileShow;
      this.isLabel = -1;
      this.tableData = this.copyTabelData;
    },
    // 文件目录 -- 文件选中
    fileClick(i) {
      this.isLabel = i;
      this.tableData = this.copyTabelData.filter((item) => {
        return item.cid == i;
      });
    },
    // 文件目录 -- 展示数据
    filStoragesDatas() {
      // this.$pageInfoTil.pageInfo("/Goods_Category/lst").then((data) => {
      //   this.filStorages = data;
      // });
      // this.$http.post("/Goods_Category/lst").then(data => {
      //   this.filStorages = data.data
      // })
      this.$http
        .post("/Goods_Category/lst", {
          commercial_id: this.commercial_id,
        })
        .then((res) => {
          this.filStorages = res.data.data;
        });
    },
    // 表格数据
    inquireList() {
      this.$http
        .post(
          "/product/lst",
          ({
            cid: this.cid,
            commercial_id: this.commercial_id,
            currentPage: this.currentPage,
            currentLength: this.currentLength,
            commercial_id: this.commercial_id,
          })
        )
        .then((res) => {
          this.isLoading = false;
          this.tableData = res.data.data;
          this.copyTabelData = [...this.tableData];
          this.tabletotalNum = res.data.count;
        });
    },
    // 商品档案搜索
    handleInquire() {
      this.$http
        .post(
          "/product/search",
          ({
            commercial_id: this.commercial_id,
            search: this.searchObj.inquireValue,
            product_label: this.searchObj.firstSelect,
            product_type: this.searchObj.secondSelect,
          })
        )
        .then((res) => {
          this.isLoading = false;
          this.tableData = res.data.data;
          this.copyTabelData = [...this.tableData];
          this.tabletotalNum = res.data.count;
        });
    },
    // 确认
    handleConfirm() {
      const idArr = this.multipleSelection.map((item) => {
        return item.id;
      });
      this.$http
        .post(
          "/product/product_detail",
          this.$qs.stringify({
            id: idArr,
          })
        )
        .then((res) => {
          this.$refs.multipleTable.clearSelection();
          this.$emit("productDetailData", res.data);
        });
    },
  },
};
</script>
<style scoped lang="less">
.file_form {
  padding: 10px;

  .file {
    padding: 20px;

    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.label {
  background-color: #ffeabf;
}
</style>
